import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import Player from '@vimeo/player';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';

import Muuri from 'muuri';
import InfiniteScroll from 'infinite-scroll';

import lity from 'lity';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

const body = $('body');
let winH = $(window).height();
let winW = $(window).width();

let tar_offset = 100;



function nav_show(){
	body.addClass('navopen');
	body.removeClass('navclose');
	const _el = document.querySelectorAll('.nav_list');
	gsap.fromTo(_el,{y:20, opacity:0},{
		y:0,
		opacity:1,
		duration: 0.6,
		ease: "power4.out",
		delay: 0.4,
		stagger: 0.02,
	});
}
function nav_hide(){
	body.removeClass('navopen');
	body.addClass('navclose');
}
function navBtn (){
	const _nav_btn = document.getElementById('nav_btn');
	const _el1 = _nav_btn.querySelector('.ellipse');
	const _el2 = _nav_btn.querySelector('.hexagon');
	_nav_btn.addEventListener('mouseenter', function(){
		gsap.to(_el1,{ x:22, y:-16, duration: 0.6, ease: "power4.out"});
		gsap.to(_el2,{ x:-22, y:16, duration: 0.6, ease: "power4.out"});
	});
	_nav_btn.addEventListener('mouseleave', function(){
		gsap.to(_el1,{ x:0, y:0, duration: 0.6, ease: "power4.out"});
		gsap.to(_el2,{ x:0, y:0, duration: 0.6, ease: "power4.out"});
	});
}
function navInit(){
	body.addClass('navclose');
	$('#nav_btn').on('click', function(){
		nav_show();
	});
	$('#nav_btn_close').on('click', function(){
		nav_hide();
	});
	
	navBtn();
	footer_navexpand();
	spnav_expand();
	// window.addEventListener('scroll', function(){
	// 	nav_hide();
	// });
}


function nav_white() {
	const _nav_white = document.querySelectorAll('.nav_white');
	if(_nav_white.length){
		_nav_white.forEach(_white_sec => {
			const nav_scrollTrigger = ScrollTrigger.create({
				trigger: _white_sec,
				start: "top top+=1px",
				end: "bottom top",
				onEnter: () => {
					body.addClass('navwh');
				},
				onLeave: () => {
					body.removeClass('navwh');
				},
				onEnterBack: () => {
					body.addClass('navwh');
				},
				onLeaveBack: () => {
					body.removeClass('navwh');
				},
			});	
		});
	}
}


function spnav_expand() {
	const _nav = document.getElementById('nav');
	const _has_sub = _nav.querySelectorAll('.has_sub');
	_has_sub.forEach((_tar, i) => {
		const _sub_ul = _tar.querySelector('.sub_nav_sp');
		gsap.set(_sub_ul,{ height:0, autoAlpha:0});
		_sub_ul.classList.add('hide');
		_tar.addEventListener('click', function(){
			_sub_ul.classList.toggle('hide');
			if(_sub_ul.classList.contains('hide')){
				gsap.to(_sub_ul,{ height:0, autoAlpha:0, duration: 0.6, ease: "power4.out"});
			}
			else{
				gsap.to(_sub_ul,{ height:'auto', autoAlpha:1, duration: 0.6, ease: "power4.out"});
			}
		});
	});
}


function footer_navexpand() {
	const _footer_nav = document.getElementById('footer_nav');
	const _has_sub = _footer_nav.querySelectorAll('.has_sub');
	_has_sub.forEach((_tar, i) => {
		const _sub_ul = _tar.querySelector('.sub_ul');
		gsap.set(_sub_ul,{ height:0, autoAlpha:0});
		_sub_ul.classList.add('hide');
		_tar.addEventListener('mouseenter', function(){
			gsap.to(_sub_ul,{ height:'auto', autoAlpha:1, duration: 0.6, ease: "power4.out"});
			_sub_ul.classList.remove('hide');
		});
		_tar.addEventListener('mouseleave', function(){
			_sub_ul.classList.add('hide');
			gsap.to(_sub_ul,{ height:0, autoAlpha:0, duration: 0.6, ease: "power4.out"});
		});
	});
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.set(_elmc,{ opacity:0, y:60 });
		gsap.to(_elmc,
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1),
				},
				onStart: function(){
					_tar.classList.add('elon');
				}
			}
		);
	});
	document.querySelectorAll('.obj').forEach((_tar, i) => {
		const obj_scrollTrigger = ScrollTrigger.create({
			trigger: _tar,
			start: "top bottom-=10%",
			onEnter: () => {
				_tar.classList.add('on');
			},
		});
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
	body.addClass('scrltop');
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


function anchorLink(){
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		// e.stopPropagation();
		nav_hide();
		let hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		let currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}

function expandFunc() {
	const expand = (parent, state) => {
		if(state){
			parent.classList.add('open');
		}else{
			parent.classList.remove('open');
		}
		const exc = parent.querySelector('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: (!state) ? 0 : "auto",
				duration:0.3,
				ease: "power2.inOut",
				onComplete: () => {
					ScrollTrigger.refresh();
				}
			});
		});
	}
	const expand_event = (e) => {
		let parent = e.target.closest('.ex');
		const result = parent.classList.contains('open');
		if (result) {
			expand(parent, false);
		}else{
			expand(parent, true);
		}
	};
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


function sticky_block(){
	let panels = gsap.utils.toArray(".sticky_blc");
	panels.forEach((panel, i) => {
		ScrollTrigger.create({
			trigger: panel,
			start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom",
			pin: true, 
			pinSpacing: false 
		});
	});
}


function vimeo_thumbnail() {
	const videos = document.querySelectorAll('.vimeo_id');
	if(videos.length){
		videos.forEach(video => {
			const vimeo_id = video.getAttribute('data-vimeo_id');
			fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${vimeo_id}`)
			.then(response => {
				return response.json();
			})
			.then(data => {
				const thumbnail_url = data.thumbnail_url;
				video.style.backgroundImage = `url(${thumbnail_url})`;
			})
		});
	}

}

function top_service(){
	gsap.from('#service_zoom',
		{ scale: 0.7, 
			scrollTrigger: {
				trigger: '#top_services',
				start: "top center",
				end: "bottom bottom",
				scrub: 1,
				onRefresh: self => self.progress === 1 && self.animation.progress(1),
			}
		}
	);

	let _double = document.querySelector('#service_zoom .double');
	if ('ontouchstart' in window || navigator.maxTouchPoints) {
		_double.addEventListener('click', function (e) {
			if(!e.target.classList.contains('hover')){
				e.preventDefault();
			}
			_double.classList.toggle('hover');
		});
		document.addEventListener('touchstart', function (e) {
			if (!_double.contains(e.target)) {
				_double.classList.remove('hover');
			}
		});
	}
}


function top_boldlens(){
	const iframe = document.getElementById('vimeo_boldlens');
	const player = new Player(iframe);
	const _top_boldlens = document.getElementById('top_boldlens');
	_top_boldlens.addEventListener('click', function(e){
		e.stopPropagation();
		player.getVolume().then(volume => {
			if (volume === 0) {
				player.setVolume(1);
			} else {
				player.setVolume(0);
			}
		});
	});

	// const tl = gsap.timeline({
	// 	scrollTrigger: {
	// 		trigger: '#top_boldlens',
	// 		start: "top center",
	// 		end: "bottom bottom",
	// 		scrub: 1,
	// 		onRefresh: self => self.progress === 1 && self.animation.progress(1),}
	// })
	// .from('#top_boldlens i', {scale:0, rotation: 360})
}


function top_wtt(){
	const wtt_swiper = new Swiper('#wtt_slider', {
		slidesPerView: 1.2,
		spaceBetween: 10,
		speed: 600,
		keyboard: {
			enabled: true,
		},
		mousewheel: {
			enabled: true,
			forceToAxis: true,
		},
		scrollbar: {
			el: '.swiper-scrollbar',
			draggable: true,
			dragSize: 100,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 20,
				scrollbar: {
					dragSize: 'auto',
				},
			},
			1024: {
				slidesPerView: 3,
				scrollbar: {
					dragSize: 'auto',
				},
			},
			1200: {
				slidesPerView: 4,
				scrollbar: {
					dragSize: 'auto',
				},
			},
		},
	});
}


function rotate_mask(tar, r0, r1, r0sp, r1sp, _top=false) {
	const mm = gsap.matchMedia();
	let _r0;
	let _r1;
	let tl = [];
	const updateGsap = () => {
		if(tl) {
			tl.forEach((tl) => {
				tl.kill();
			});
			tl = [];
		}
		const anime = (_tar, _r0, _r1) => {
			let _start,_end;
			if(_top){
				_start = "top top=-1px";
				_end = "bottom top";
			}else{
				_start = "top bottom";
				_end = "bottom top";
			}
			const _el1 = tar.querySelector('.kv_elm');
			const _el2 = tar.querySelector('.img_blc');
			const _el_arr = [_el1, _el2];
			_el_arr.forEach(_el => {
				const _rotate0 = _el === _el2 ? -_r0 : _r0;
				const _rotate1 = _el === _el2 ? -_r1 : _r1;
				gsap.set(_el,{ rotate: _rotate0 });
				const tl_anime = gsap.timeline({
					scrollTrigger: {
						trigger: _tar,
						start: _start,
						end: _end,
						scrub: 1,
						onRefresh: self => self.progress === 1 && self.animation.progress(1),
					}
				});
				tl_anime.to(_el,
					{ rotate: _rotate1,
						transformOrigin: 'center center',
					}
				);
				tl.push(tl_anime);
			});
		}
		anime(tar, _r0, _r1);
	}
	mm.add("(min-width: 768px)", () => {
		_r0 = r0;
		_r1 = r1;
		updateGsap();
	});
	mm.add("(max-width: 767px)", () => {
		_r0 = r0sp;
		_r1 = r1sp;
		updateGsap();
	});
}

function service_kv() {
	const pages = document.querySelector('.pages');
	const page_id = pages.getAttribute('id');
	const servicepage = document.getElementById(page_id);
	const sec = servicepage.querySelector('.kv_elm_sec');
	if(page_id=='branding'){
		rotate_mask(sec, 0, 30, 0, 60, true);
	}
	if(page_id=='creative'){
		rotate_mask(sec, 60, 30, 0, 60, true);
	}
	if(page_id=='pr-consumer'){
		rotate_mask(sec, -90, -120, 0, 60, true);
	}
	if(page_id=='pr-corporate'){
		rotate_mask(sec, -90, -120, 0, 60, true);
	}
}


function sub_categories() {
	if( document.querySelector('#sub_categories_list') ){
		const service_list = new Swiper('#sub_categories_list', {
			direction: 'vertical',
			slidesPerView: 6,
			spaceBetween: 0,
			speed: 600,
			keyboard: {
				enabled: true,
			},
			mousewheel: {
				enabled: true,
				forceToAxis: true,
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
				dragSize: 'auto',
			},
		});
	}
}

function team_list() {
	const service_list = new Swiper('#team_list', {
		slidesPerView: 1.2,
		spaceBetween: 20,
		speed: 600,
		keyboard: {
			enabled: true,
		},
		mousewheel: {
			enabled: true,
			forceToAxis: true,
		},
		scrollbar: {
			el: '.swiper-scrollbar',
			draggable: true,
			dragSize: 100,
		},
		breakpoints: {
			768: {
				slidesPerView: 3.5,
				spaceBetween: 40,
				scrollbar: {
					dragSize: 'auto',
				},		
			},
		},
		on: {
			init: function(){
				ScrollTrigger.refresh();
			}
		},
	});
}


function works_navexpand() {
	const work_sec = document.querySelector("#work_title_sec")
	const _work_cat = document.querySelector('#work_cat');
	const _work_cat_ul = document.querySelector('#work_cat ul');
	const _work_cat_ul_li = document.querySelectorAll('#work_cat ul li');

	gsap.set(_work_cat_ul_li,{ autoAlpha:0});
	const tl = gsap.timeline({paused:true});
	let mm = gsap.matchMedia();
	mm.add("(min-width: 1025px)", () => {
		gsap.set(_work_cat_ul,{ width:0, height:0});
		tl.to(_work_cat_ul,{ width:'auto', duration: 0.2 })
			.to(_work_cat_ul_li,{ autoAlpha:1,duration: 0.2 });
	});
	mm.add("(max-width: 1024px)", () => {
		gsap.set(_work_cat_ul,{ width:0, height:0});
		tl.to(_work_cat_ul,{ height:'auto', duration: 0.2 })
			.to(_work_cat_ul_li,{ autoAlpha:1,duration: 0.2 });
	});
	
	work_sec.addEventListener('mouseenter', function(){
		tl.play();
		work_sec.classList.add("active");
	});
	work_sec.addEventListener('mouseleave', function(){
		tl.reverse();
		work_sec.classList.remove("active");
	});
}


function infScroll_func(){
	const infScroll = new InfiniteScroll( '#work_list', {
		append: '.item',
		path: '.nextpage a',
		hideNav: '.nextpage',
		scrollThreshold: true,
		status: '.page-load-status',
		history: false,
	});
	infScroll.on( 'append', (body, path, items, response ) => {
		items.forEach((_tar, i) => {
			gsap.set(_tar,{ opacity:0, y:60 });
			gsap.to(_tar,
				{ opacity:1, y:0, 
					duration: 1.2,
					ease: "power4.out",
					stagger: 0.1,
					scrollTrigger: {
						trigger: _tar,
						start: "top bottom-=10%",
						onRefresh: self => self.progress === 1 && self.animation.progress(1),
					},
					onStart: function(){
						_tar.classList.add('elon');
					}
				}
			);
		});
		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	});
}


function post_list(){
	const grid = new Muuri('#post_list', {
		showDuration: 600,
		hideDuration: 0,
	});
	const items = grid.getItems();
	grid.hide(items);
	
	const scrollTrigger = ScrollTrigger.create({
		trigger: '#post_list',
		start: "top bottom",
		onEnter: () => {
			items.forEach((item, i) => {
				setTimeout(() => {
					grid.show([item]);
				}, i * 100);
			});
		},
	});

	if( document.querySelector('.nextpage') ){
		const infScroll = new InfiniteScroll( '#post_list', {
			append: '.item',
			path: '.nextpage a',
			hideNav: '.nextpage',
			scrollThreshold: false,
			button: '.view-more',
			status: '.page-load-status',
			history: false,
		});
		infScroll.on( 'append', (body, path, items, response ) => {
			imagesLoaded( items, function() {
				grid.add( items );
			});
			
			grid.on('add', function (items) {
				grid.hide(items);
				items.forEach((item, i) => {
					setTimeout(() => {
						grid.show([item]);
					}, i * 100);
				});
			});
		});
	}
}



function sectors_video(){
	const _sectors = document.querySelectorAll('.sector');
	_sectors.forEach(_sector => {
		const iframe = _sector.querySelector('.vimeo_sectors');
		const player = new Player(iframe);
		player.pause();
		_sector.addEventListener('mouseenter', function(e){
			player.play();
		});
		_sector.addEventListener('mouseleave', function(e){
			player.pause();
		});
	});
}


function about_purpose(){
	const _purpose = document.querySelectorAll('.purpose');
	_purpose.forEach((_pur, i) => {
		gsap.from(_pur, {
			y: () => 100*(i+1),
			scrollTrigger: {
				trigger: "#purpose_list",
				start: "top bottom",
				end: "top top",
				scrub: 1,
				onRefresh: self => self.progress === 1 && self.animation.progress(1),
			}
		});
	});
}


function about_swiper(){
	const _swiper_def = document.querySelectorAll('.swiper_def');
	_swiper_def.forEach((_tar, i) => {
		let swiper;
		const swiperOptions = {
			slidesPerView: 1,
			spaceBetween: 10,
			speed: 600,
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
				dragSize: 100,
			},
			breakpoints: {
				768: {
					scrollbar: {
						dragSize: 'auto',
					},
				},
			},	
		};
		const createSwiper = () => {
			if(!swiper) swiper = new Swiper(_tar, swiperOptions);
		}
		const destroySwiper = () => {
			if (swiper) {
				swiper.destroy(false, true);
				swiper = null;
			}
		}
		const mm = gsap.matchMedia();
		mm.add("(min-width: 768px)", () => {
			destroySwiper();
		});
		mm.add("(max-width: 767px)", () => {
			createSwiper();
		});
	});
}


function single_swiper(){
	let swiper;
	const target = document.querySelector('#single_swiper');
	const swiperOptions = {
		slidesPerView: 1,
		spaceBetween: 20,
		speed: 600,
		loop: true,
	}
	const createSwiper = () => {
		if(!swiper) swiper = new Swiper(target, swiperOptions);
	}
	const destroySwiper = () => {
		if (swiper) {
			swiper.destroy(false, true);
			swiper = null;
		}
	}
	const mm = gsap.matchMedia();
	mm.add("(min-width: 768px)", () => {
		destroySwiper();
	});
	mm.add("(max-width: 767px)", () => {
		createSwiper();
	});
}


function single_work_swiper(){
	const _target = document.querySelectorAll('.swiper');
	_target.forEach((_tar, i) => {
		const swiper = new Swiper(_tar, {
			slidesPerView: 1,
			spaceBetween: 20,
			speed: 600,
			loop: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		});
	});
}


function single_work_parallax(){
	const _overlap = document.querySelectorAll('.overlap');
	_overlap.forEach((_tar, i) => {
		const _parent = _tar.closest('.col');
		gsap.fromTo(_tar, {yPercent:50}, {
			yPercent: -100,
			scrollTrigger: {
				trigger: _parent,
				start: "top bottom",
				end: "bottom top",
				scrub: 1,
				onRefresh: self => self.progress === 1 && self.animation.progress(1),
			}
		});
	});
}


function boldlens_func(){
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: '#boldlens_services',
			start: "top bottom-=30%",
			end: "top center",
			scrub: 1,
			onRefresh: self => self.progress === 1 && self.animation.progress(1),}
	})
	.to('#boldlens_kv i', {scale:0, rotation: 360})
}


function careers_kvstar(){
	const swiper = new Swiper('#kv_star', {
		direction: 'vertical',
		slidesPerView: 2,
		spaceBetween: 10,
		speed: 600,
		loop: true,
		autoplay: {
			delay: 1000,
		},
		breakpoints: {
			768: {
				direction: 'horizontal',
				spaceBetween: 20,
			},
		},
	});
}


function careers_boxslider() {
	const _target = document.querySelectorAll('.box_swiper');
	_target.forEach((_tar, i) => {
		const wtt_swiper = new Swiper(_tar, {
			slidesPerView: 1,
			spaceBetween: 10,
			speed: 600,
			keyboard: {
				enabled: true,
			},
			mousewheel: {
				enabled: true,
				forceToAxis: true,
			},
			scrollbar: {
				el: '.swiper-scrollbar',
				draggable: true,
				dragSize: 100,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 20,
					scrollbar: {
						dragSize: 'auto',
					},	
				},
				1024: {
					slidesPerView: 3,
					scrollbar: {
						dragSize: 'auto',
					},
				},
				1200: {
					slidesPerView: 4,
					scrollbar: {
						dragSize: 'auto',
					},
				},
			},
		});
	});
}

function lityFunc() {
	gsap.utils.toArray('.lityelm').forEach(target => {
		const vimeoid = target.dataset.vimeoid;
		target.addEventListener('click', function(){
			console.log(vimeoid);
			lity('//player.vimeo.com/video/'+vimeoid);
		});
	});
}


function counterAnime(){
	const counterStart = (el) => {
		let _count = el.dataset.count;
		let _digit = 1;
		let _countStr = String(_count);
		let _countStrArr = _countStr.split('.');
		if(_countStrArr.length > 1){
			_digit = '0.' + '1'.padStart(_countStrArr[1].length, '0');
		}
		gsap.to(el, {
			textContent: _count,
			duration: 1,
			ease: "power1.out",
			snap : { textContent: _digit }
		});
	}
	gsap.utils.toArray('.counter').forEach(function (el) {
		const cou_scrollTrigger = ScrollTrigger.create({
			trigger: el,
			start: "top bottom-=10%",
			once: true,
			onEnter: self => {
				counterStart(el);
			},
		});
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();
		nav_hide();
		nav_white();
		anchorLink();
		elFunc();
		expandFunc();
		sticky_block();
		vimeo_thumbnail();
		lityFunc();
		sub_categories();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top bgbk');
		top_service();
		top_boldlens();
		top_wtt();
		post_list();
	},
	serviceFunc: function(){
		service_kv();
		team_list();
	},
	works_archiveFunc: function(){
		works_navexpand();
		infScroll_func();
	},
	sectors_Func: function(){
		sectors_video();
	},
	sector_archiveFunc: function(){
		infScroll_func();
	},
	post_archiveFunc: function(){
		post_list();
	},
	aboutFunc: function(){
		about_purpose();
		about_swiper();
	},
	post_singleFunc: function(){
		single_swiper();
	},
	work_singleFunc: function(){
		single_work_swiper();
		single_work_parallax();
		counterAnime();
	},
	boldlensFunc: function(){
		boldlens_func();
		team_list();
	},
	careersFunc: function(){
		careers_kvstar();
		careers_boxslider();
	}
};


function pjaxSettings(){

	// barba.use(barbaPrefetch);

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if (href == null) {
				href = el.getAttribute('xlink:href');
		}
		if (el.classList.contains('pj')) {
			return false;
		}
		if (el.classList.contains('nopj')) {
				return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
				el.setAttribute('target', '_blank');
				return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/, "");
		if (href.startsWith(location.protocol + '//' + location.host)) {
				if (href.indexOf('#') > -1 && extract_hash == url) {
						return false;
				}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
				el.setAttribute('target', '_blank');
				return true;
		}
		return false;
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					resolve();
				}});
			}
		})
	}

  function executeInlineScripts(container) {
    const scripts = container.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.textContent = script.textContent;
      }
      script.parentNode.insertBefore(newScript, script.nextSibling);
    });
  }
  
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('#footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();

				var lazyLoadInstance = new LazyLoad({
					// Your custom settings go here
				});
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl bgbk navwh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
    executeInlineScripts(data.next.container);
		scroll(data);
		async function callerFun(){
			pageCheck();
			if( document.querySelectorAll('wpcf7') ){
				contact_Cf7();
			}	
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onStart:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

async function pageCheck() {
	await new Promise((resolve, reject) => {
		PageEvents.commonFunc();

		if( document.getElementById('top') ){
			PageEvents.topFunc();
		}else{
			body.addClass('inpage');
		}
		if( document.getElementById('service_page') ){
			PageEvents.serviceFunc();
		}
		if( document.getElementById('works_archive') ){
			PageEvents.works_archiveFunc();
		}
		if( document.getElementById('sectors') ){
			PageEvents.sectors_Func();
		}
		if( document.getElementById('sector_archive') ){
			PageEvents.sector_archiveFunc();
		}
		if( document.getElementById('post_archive') ){
			PageEvents.post_archiveFunc();
		}
		if( document.getElementById('about') ){
			PageEvents.aboutFunc();
		}
		if( document.getElementById('post_single') ){
			PageEvents.post_singleFunc();
		}
		if( document.getElementById('works_single') ){
			PageEvents.work_singleFunc();
		}
		if( document.getElementById('boldlens') ){
			PageEvents.boldlensFunc();
		}
		if( document.getElementById('careers') ){
			PageEvents.careersFunc();
		}
		
		body.addClass('ready');
		resolve();
	})
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
	window.addEventListener('load', function(){
		body.addClass('siteloaded');
	});
}

initialize();

})();
})( jQuery );

}